<template>
  <v-main id="about-me-content" :class="$vuetify.theme.dark ? 'transparent-dark' : 'transparent-light'">
    <v-layout column align-center justify-center>
      <h1 class="font-weight-medium display-2 text-center">About</h1>
      <v-icon style="transform: scale(3,1)" class="mb-6">mdi-color-helper</v-icon>
      <v-container>
        <v-row class="mb-3 mt-12" justify="center">
          <v-col sm="6" md="3" lg="3" xl="2">
            <v-card width="350px" flat color="rgb(255, 0, 0, 0.0)" align="center" justify="center" class="mx-auto">
              <v-btn width="100px" height="100px" class="mb-4 disable-events" fab dark x-large color="secondary" elevation="2">
                <v-icon x-large dark>mdi-code-tags</v-icon>
              </v-btn>
              <p class="text-center font-weight-bold headline">Software Development</p>
              <p class="text-center subtitle-1 px-8">Over 8 years of commercial experience in full-stack developement for web, desktop and mobile platforms</p>
            </v-card>
          </v-col>
          <v-col sm="6" md="3" lg="3" xl="2">
            <v-card width="350px" flat color="rgb(255, 0, 0, 0.0)" align="center" justify="center" class="mx-auto">
              <v-btn width="100px" height="100px" class="mb-4 disable-events" fab dark x-large color="secondary" elevation="2">
                <v-icon x-large dark>mdi-server-network</v-icon>
              </v-btn>
              <p class="text-center font-weight-bold headline">DevOps</p>
              <p class="text-center subtitle-1 px-8">Managing cloud and bare-metal infrastructure, CI/CD, system automation, ensuring efficient and scalable deployments</p>
            </v-card>
          </v-col>
          <v-col sm="6" md="3" lg="3" xl="2">
            <v-card width="350px" flat color="rgb(255, 0, 0, 0.0)" align="center" justify="center" class="mx-auto">
              <v-btn width="100px" height="100px" class="mb-4 disable-events" fab dark x-large color="secondary" elevation="2">
                <v-icon x-large dark>mdi-database-cog</v-icon>
              </v-btn>
              <p class="text-center font-weight-bold headline">Data Engineering</p>
              <p class="text-center subtitle-1 px-8">Building scalable pipelines, optimising workflows, and managing big data systems for reliable data delivery</p>
            </v-card>
          </v-col>
          <v-col sm="6" md="3" lg="3" xl="2">
            <v-card width="350px" flat color="rgb(255, 0, 0, 0.0)" align="center" justify="center" class="mx-auto">
              <v-btn width="100px" height="100px" class="mb-4 disable-events" fab dark x-large color="secondary">
                <v-icon x-large dark>mdi-brain</v-icon>
              </v-btn>
              <p class="text-center font-weight-bold headline">Data Analysis</p>
              <p class="text-center subtitle-1 px-8">Data analysis, visualisation, and machine learning, extracting insights to drive data-driven decisions</p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <template>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-12">
            <v-flex xs12 sm10 md6 lg6 xl4 class="mx-md-0">
              <v-card class="transparent">
                <div ref="avatar">
                  <v-img :aspect-ratio="1/1" src="../assets/images/avatar.jpg" lazy-src="../assets/images/avatar_lowres.jpg"></v-img>
                </div>
              </v-card>
            </v-flex>
            <v-flex xs12 sm10 md6 lg6 xl4 class="mx-md-0">
              <v-card class="px-2"  flat color="rgb(255, 0, 0, 0.0)">
                <v-layout column justify-space-between fill-height>
                  <v-card-text>
                    <h3 class="display-1 mb-2 text-left">Who am I</h3>
                    <p>
                      I am a software engineer with over 8 years of commercial experience spanning full-stack web development, cloud-native and bare-metal distributed systems, DevOps, and data engineering, analysis, and visualisation. Having worked in companies of all sizes, from pre-seed startups to global corporations, I tackled diverse challenges and brought scalable, efficient systems from concept to deployment. I thrive on solving complex problems, leveraging cutting-edge technologies to drive innovation and create impactful solutions. With a deep passion for data science, I specialise in building systems that uncover patterns, enable advanced analytics, and integrate machine learning to deliver intelligent, data-driven results.
                    </p>
                  </v-card-text>
                  <v-card-text style="padding-top: 0 !important;">
                    <h3 class="display-1 mb-2 text-left">Social</h3>
                    <v-hover>
                      <template v-slot="{ hover }">
                        <v-btn href="https://www.linkedin.com/in/dan-pavlov" target="_blank" class="mr-4" fab dark medium color="secondary" :elevation="hover ? 6 : 2">
                          <v-icon dark>mdi-linkedin</v-icon>
                        </v-btn>
                      </template>
                    </v-hover>
                    <v-hover>
                      <template v-slot="{ hover }">
                        <v-btn href="https://github.com/dan-pavlov" target="_blank" class="mr-4" fab dark medium color="secondary" :elevation="hover ? 6 : 2">
                          <v-icon dark>mdi-github</v-icon>
                        </v-btn>
                      </template>
                    </v-hover>
                    <v-hover>
                      <template v-slot="{ hover }">
                        <v-btn href="https://www.kaggle.com/danpavlov" target="_blank" class="mr-4" fab dark medium color="secondary" :elevation="hover ? 6 : 2">
                          <p class="text-lowercase my-auto headline">k</p>
                        </v-btn>
                      </template>
                    </v-hover>
                  </v-card-text>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </v-layout>
  </v-main>
</template>

<script>
import 'vue-resize/dist/vue-resize.css'

export default {
}
</script>

<style scoped>
@media (min-width: 0) {
  p {
    font-size: 22px;
    line-height: normal;
  }
  .v-card__text {
    padding: 6px !important;
  }
}
@media (min-width: 600px) {
  p {
    font-size: 22px;
    line-height: normal;
  }
  .v-card__text {
    padding: 16px !important;
  }
}
@media (min-width: 960px) {
  p {
    font-size: 15px;
    line-height: normal;
  }
  .v-card__text {
    padding: 16px !important;
  }
}
@media (min-width: 1264px) {
  p {
    font-size: 22px;
    line-height: normal;
  }
  .v-card__text {
    padding: 16px !important;
  }
}
.disable-events {
  pointer-events: none
}
</style>
