<template>
<div>
    <img v-if="darkTheme" ref="backtest" class="background__image" src="../assets/images/background/dark_blur.jpg" />
    <img v-else ref="backtest" class="background__image" src="../assets/images/background/light_blur.jpg" />

    <v-app id="inspire" class="overflow-y-auto foreground">
      <Navbar />
      <Index />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import Index from '../components/Index'
import About from '../components/About'
import Skills from '../components/Skills'
import Projects from '../components/Projects'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

export default {
  components: {
    Navbar,
    Index,
    About,
    Skills,
    Projects,
    Contact,
    Footer
  },
  computed: {
    darkTheme () {
      return this.$vuetify.theme.dark
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      const newScroll = -window.scrollY / 12
      this.$refs.backtest.style.transform = `translateY(${newScroll}px)`
    }
  }
}
</script>

<style scoped>
.background {
  position: fixed;
}
.background__image {
  position: fixed;
  overflow: visible;
  top: 0%;
  min-height: 2460px;
  min-width: 1920px;
  min-width: 110%;
  height: 110%;
}
</style>
