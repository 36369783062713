<template>
  <div>
    <v-app-bar app :elevate-on-scroll="true">

      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="toggleDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title style="padding-left: 2px">Dan Pavlov</v-toolbar-title>

      <v-switch
        v-model="$vuetify.theme.dark"
        class="hidden-md-and-down"
        style="z-index:1;margin-top:25px;margin-left:35px;"
        inset mt-2 pt-2
        color="primary"
        label="Dark theme">
      </v-switch>

      <v-switch
        :input-value="particleSwitch"
        class="hidden-md-and-down"
        :class="[particleSwitchVisible ? 'navbar-switch-scroll-on' : 'navbar-switch-scroll-off']"
        style="z-index:1;margin-top:25px;margin-left:35px;"
        inset
        color="primary"
        label="Particle Effects"
        @change="switchParticles">
      </v-switch>

      <div class="flex-grow-1"></div>

      <v-toolbar-items class="hidden-md-and-down">
        <v-btn text @click="$vuetify.goTo('#index-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">Welcome</v-btn>
        <v-btn text @click="$vuetify.goTo('#about-me-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">About</v-btn>
        <v-btn text @click="$vuetify.goTo('#skills-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">Skills</v-btn>
        <v-btn text @click="$vuetify.goTo('#projects-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">Experience</v-btn>
        <v-btn text @click="$vuetify.goTo('#contact-content', {duration: 1000, offset: 0, easing: 'easeInOutQuad'})">Contact</v-btn>
      </v-toolbar-items>

      <template v-slot:img="{ props }">
        <v-img
          v-show="$vuetify.theme.dark"
          src="../assets/images/background/dark_thin.jpg"
          v-bind="props"
          gradient="to top left, rgba(88,88,88,.2), rgba(0,0,0,0.3)"
        ></v-img>
        <v-img
          v-show="!$vuetify.theme.dark"
          src="../assets/images/background/light_thin.jpg"
          v-bind="props"
          gradient="to top left, rgba(199,199,199,.3), rgba(255,255,255,0.5)"
        ></v-img>
      </template>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary fixed width="275" class="contentprimary">

      <v-list-item two-line>
        <v-list-item-avatar>
          <v-img src="../assets/images/avatar_sm.jpg" lazy-src="../assets/images/avatar_sm.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Dan Pavlov</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item link @click="scrollToSection('#index-content')">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Welcome
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="scrollToSection('#about-me-content')">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            About Me
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="scrollToSection('#skills-content')">
        <v-list-item-icon>
          <v-icon>mdi-tools</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            My Skills
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="scrollToSection('#projects-content')">
        <v-list-item-icon>
          <v-icon>mdi-calendar-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            My Projects
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="scrollToSection('#contact-content')">
        <v-list-item-icon>
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Contact
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="switchTheme()">
        <v-list-item-icon>
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Dark Theme
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action style="margin-right:5px;">
          <v-switch
            :input-value="$vuetify.theme.dark"
            inset style="pointer-events: none;"
            color="primary">
          </v-switch>
        </v-list-item-action>
      </v-list-item>

      <v-list-item link @click="switchParticles">
        <v-list-item-icon>
          <v-icon>mdi-vector-triangle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Particle Effects
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action style="margin-right:5px;">
          <v-switch
            :input-value="particleSwitch"
            inset style="pointer-events: none;"
            color="primary">
          </v-switch>
        </v-list-item-action>
      </v-list-item>

    </v-navigation-drawer>
  </div>
</template>

<script>
import goTo from 'vuetify/es5/services/goto'
import { EventBus } from '../Helpers/event-bus'

export default {
  data () {
    return {
      drawer: false,
      particleSwitch: true,
      particleSwitchVisible: true
    }
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    scrollToSection (section) {
      this.toggleDrawer()
      goTo(section, { duration: 1000, offset: 55, easing: 'easeInOutQuad' })
    },
    switchParticles () {
      EventBus.$emit('switchParticles')
    },
    switchTheme () {
      this.$vuetify.theme.light = !this.$vuetify.theme.light
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    handleScroll (event) {
      if (window.scrollY >= 500) {
        this.particleSwitchVisible = false
      } else {
        this.particleSwitchVisible = true
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted: function () {
    EventBus.$on('switchParticles', () => {
      this.particleSwitch = !this.particleSwitch
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
@media only screen and (max-width: 425px) {
  .v-toolbar__title {
    font-size: 0.75rem !important;
  }
}
@media only screen and (max-width: 960px) {
  .v-toolbar__title {
    font-size: 1.25rem !important;
  }
}
.v-label {
  color: grey !important;
}
.navbar-switch-scroll-on {
  opacity: 1;
  transition: 0.2s;
  pointer-events: all;
}
.navbar-switch-scroll-off {
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
}
</style>
